.fist_guide_back {
  z-index: 3;
  left: 0px;
  bottom: 0px;
  position: absolute;
  width: 100%;
  height: 268px;
  opacity: 1;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.3) 61.15%,
    rgba(0, 0, 0, 0.53) 86.33%,
    rgba(0, 0, 0, 0.65) 100%
  );
  .content {
    padding-top: 94px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    ._text {
      font-size: 12px;
      font-weight: 700;
      letter-spacing: 0px;
      line-height: 16.8px;
      color: rgba(255, 255, 255, 1);
      text-align: center;
      vertical-align: top;
      margin-bottom: 18px;
    }
    .img_box {
      padding-left: 53%;
      box-sizing: border-box;
      width: 100%;
    }
    .img_box_two {
      margin-top: 19px;
      padding-left: 70%;
      box-sizing: border-box;
      width: 100%;
      position: relative;
      .left_img {
        position: absolute;
      }
    }
    .img_box_three {
      margin-top: 19px;
      padding-left: 12%;
      box-sizing: border-box;
      width: 100%;
      position: relative;
      .right_img {
        position: absolute;
        left: 22%;
      }
    }
  }
}
