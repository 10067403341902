.guide_page {
  padding-bottom: 20px;
  box-sizing: border-box;
  // height: 100vh;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  .skip_btn {
    height: 42px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-top: 22px;
    padding-right: 14px;
    box-sizing: border-box;
    // position: fixed;
    // top: 22px;
    // right: 14px;
  }
  .first_title {
    height: 50px;
    font-size: 24px;
    font-weight: 700;
    letter-spacing: 0px;
    line-height: 33.6px;
    color: rgba(23, 65, 43, 1);
    text-align: center;
    margin-bottom: 16px;
  }
  .second_title_box {
    height: 64px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    .second_title {
      text-align: center;
      width: 303px;
      height: 34px;
      font-size: 12px;
      font-weight: 400;
      letter-spacing: 0.63px;
      line-height: 16.8px;
      color: rgba(139, 139, 139, 1);
    }
  }
  .guide_check_list {
    flex: 1;
    overflow-y: auto;
    .adm-list-body {
      border: none;
    }
    .adm-list-body-inner {
      display: grid;
      grid-template-columns: auto auto auto;
      grid-gap: 10px;
      padding: 20px 36px;
      .guide_item {
        width: 90px;
        height: 90px;
        padding: 12px;
        box-sizing: border-box;
        opacity: 1;
        border-radius: 14px;
        background: rgba(255, 255, 255, 1);
        box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.08);
        position: relative;
        .guide_item_image {
        }
        .guide_item_text {
          font-size: 12px;
          font-weight: 500;
          letter-spacing: 0px;
          line-height: 16.8px;
          color: rgba(23, 72, 46, 1);
          text-align: left;
        }
        .guide_item_check {
          position: absolute;
          top: 8px;
          right: 8px;
          --icon-size: 12px;
        }
      }
    }
  }
  .swiper_tips {
    height: 84px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 14px;
    .top_text {
      font-size: 12px;
      font-weight: 400;
      letter-spacing: 0.63px;
      line-height: 16.8px;
      color: rgba(23, 72, 46, 1);
      text-align: center;
      margin-bottom: 3px;
    }
  }
  .sumbit_box {
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 51px;
    .sumbit_btn {
      color: #fff;
      width: 325px;
      height: 51px;
      opacity: 1;
      border-radius: 100px;
      background: rgba(66, 110, 82, 1);
    }
  }
}
