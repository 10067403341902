body,
p,
div {
  margin: 0;
  padding: 0;
}

img {
  opacity: 1;
  transition: all 0.5s;
}

img[data-src] {
  // opacity: 0.5;
}

.flex {
  display: flex;
}

.row {
  flex-direction: row;
}

.col {
  flex-direction: column;
}

.justify_center {
  justify-content: center;
}

.align_center {
  align-items: center;
}

@supports (bottom: constant(safe-area-inset-bottom)) or
  (bottom: env(safe-area-inset-bottom)) {
  .iosPB {
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
  }
}

:root:root {
  --adm-color-primary: #426e52;
}
