.upload_page {
  display: flex;
  flex-direction: column;
  // height: 100vh;
  .top_bar {
    display: flex;
    flex-direction: row;
    height: 45px;
    align-items: center;
    margin-bottom: 40px;
    .back_box {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background: rgba(235, 242, 240, 1);
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin: 0px 63px 0px 25px;
    }

    .top_bar_text {
      font-size: 18px;
      font-weight: 700;
      letter-spacing: 0px;
      line-height: 25.2px;
      color: rgba(23, 72, 46, 1);
    }
  }
  .content_box {
    padding: 0px 15px;
    flex: 1;
    .upload_area {
      width: 345px;
      height: 231px;
      opacity: 1;
      border-radius: 20px;
      background: rgba(255, 255, 255, 1);
      box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.08);
      padding: 16px 22px;
      --placeholder-color: rgba(23, 72, 46, 0.49);
      box-sizing: border-box;
      margin-bottom: 20px;
    }
    .upload_file {
      .audio_file_title {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 15px;
        .first {
          font-size: 14px;
          font-weight: 700;
          letter-spacing: 0px;
          line-height: 19.6px;
          color: rgba(23, 65, 43, 1);
        }
        .second {
          font-size: 10px;
          font-weight: 500;
          letter-spacing: 0.63px;
          line-height: 14px;
          color: rgba(139, 139, 139, 1);
        }
      }
    }
  }
  .upload_btn {
    width: 74px;
    height: 74px;
    opacity: 1;
    border-radius: 14px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.08);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;

    .upload_input {
      position: absolute;
      opacity: 0;
      top: 0px;
      right: 0px;
      top: 0px;
      bottom: 0px;
    }
  }
  .bottom {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 80px;
    .sumbit_btn {
      width: 325px;
      height: 51px;
      opacity: 1;
      border-radius: 100px;
      color: #fff;
      background: rgba(66, 110, 82, 1);
    }
  }
}
