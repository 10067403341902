.mine {
  // height: 100vh;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  .top_bar {
    display: flex;
    flex-direction: row;
    height: 45px;
    align-items: center;
    margin-bottom: 48px;
    .back_box {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background: rgba(235, 242, 240, 1);
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin: 0px 63px 0px 25px;
    }

    .top_bar_text {
      font-size: 18px;
      font-weight: 700;
      letter-spacing: 0px;
      line-height: 25.2px;
      color: rgba(23, 72, 46, 1);
    }
  }
  .main_box {
    flex: 1;
    .personal_msg_box {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      position: relative;
      .personal_msg {
        width: 345px;
        height: 139px;
        opacity: 1;
        border-radius: 10px;
        background: rgba(255, 255, 255, 1);
        border: 0.5px solid rgba(255, 255, 255, 0);
        box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.08);
        // clip-path: path();
        .avatar {
          left: 50%;
          transform: translate(-49%, -40%);
          position: absolute;
          width: 80px;
          height: 80px;
          opacity: 1;
          border-radius: 40px;
        }
        .msg_first {
          font-size: 16px;
          font-weight: 700;
          letter-spacing: 0px;
          line-height: 22.4px;
          color: rgba(23, 72, 46, 1);
          text-align: center;
          margin-top: 60px;
          margin-bottom: 19px;
        }
        .msg_second {
          font-size: 12px;
          font-weight: 400;
          letter-spacing: 0px;
          line-height: 16.8px;
          color: rgba(23, 72, 46, 1);
          text-align: center;
        }
      }
    }
    .tag_box {
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin-top: 15px;
      .tag_content {
        padding: 15px;
        box-sizing: border-box;
        width: 345px;
        height: 155px;
        opacity: 1;
        border-radius: 20px;
        background: rgba(255, 255, 255, 1);
        box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.08);
        .tag_top_bar {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 12px;
          .left_title {
            font-size: 14px;
            font-weight: 700;
            letter-spacing: 0px;
            line-height: 19.6px;
            color: rgba(23, 65, 43, 1);
            text-align: left;
            vertical-align: top;
          }
          .more_btn {
            background: #ebf2f0;
            line-height: 23px;
            text-align: center;
            width: 82px;
            height: 23px;
            border: 1px solid rgba(66, 110, 82, 1);
            border-radius: 11px;
          }
        }
        .mine_tag_list {
          display: grid;
          grid-template-columns: auto auto auto;
          grid-gap: 16px;
          .guide_item {
            width: 90px;
            height: 90px;
            padding: 12px;
            box-sizing: border-box;
            opacity: 1;
            border-radius: 14px;
            background: rgba(255, 255, 255, 1);
            box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.08);
            position: relative;
            .guide_item_image {
            }
            .guide_item_text {
              font-size: 12px;
              font-weight: 500;
              letter-spacing: 0px;
              line-height: 16.8px;
              color: rgba(23, 72, 46, 1);
              text-align: left;
            }
          }
        }
      }
    }
  }
  .sumbit_box {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 80px;
    .sumbit_btn {
      width: 325px;
      height: 51px;
      opacity: 1;
      border-radius: 100px;
      color: #fff;
      background: rgba(66, 110, 82, 1);
    }
  }
}
