.audio_page {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  // height: 100vh;
  // overflow-y: hidden;
  .song_top {
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    .name {
      font-size: 14px;
      font-weight: 700;
      letter-spacing: 0px;
      line-height: 19.6px;
      color: rgba(23, 65, 43, 1);
      text-align: center;
      margin-bottom: 8px;
    }
    .author {
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0.63px;
      line-height: 16.8px;
      color: rgba(139, 139, 139, 1);
      margin-bottom: 36px;
    }
    .top_gradient {
      display: flex;
      flex-direction: row;
      justify-content: center;
      z-index: 2;
      bottom: -178px;
      position: absolute;
      width: 100%;
      height: 178px;
      opacity: 1;
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 0) 100%
      );
    }
  }
  .lry_list {
    display: flex;
    position: relative;
    flex: 1;
    overflow-y: auto;
    .song_lists_ul {
      list-style: none;
      padding: 0px 29px;
      li {
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0px;
        line-height: 22.4px;
        color: rgba(23, 65, 43, 0.5);
        margin-top: 36px;
        margin-bottom: 36px;
        text-align: center;
      }
    }
  }
  .audio_auth {
    height: 46px;
    // padding-bottom: 22px;
    box-sizing: border-box;
    width: 100%;
    align-items: center;
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    position: relative;
    .voice_icon {
      margin-right: 16px;
      margin-left: 20px;
    }
    .like_icon {
      margin-left: 11px;
      margin-right: 15px;
    }
    .progress_slide {
      flex: 1;
      // overflow: hidden;
      .wave {
        width: 270px;
      }
      .wave_animation {
        width: 270px; // 根据需要设置图片大小
        animation: wave-animation 1s infinite;
      }
      @keyframes wave-animation {
        0% {
          transform: translateX(0);
        }
        50% {
          transform: translateX(-20px);
        }
        100% {
          transform: translateX(0);
        }
      }
    }
    .bottom_gradient {
      top: -217px;
      position: absolute;
      width: 100%;
      height: 217px;
      opacity: 1;
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 100%
      );
    }
  }
  //工具栏
  .tool_box {
    width: 100%;
    height: 48px;
    // display: flex;
    // flex-direction: row;
    // align-items: center;
    // text-align: center;
    // display: flex;
    // flex-direction: row;
    // justify-content: center;
    // align-items: center;
    .previous {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      height: 100%;
    }
    .start_stop_recording {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      height: 100%;
    }
    .next_previous {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      height: 100%;
    }
  }
  .progress_bar {
    position: relative;
    width: 100%;
    height: 5px;
    margin-top: 5px;
    border-radius: 10px;
    background-color: rgba(204, 225, 214, 1);
    .blue_bar {
      height: 5px;
      position: absolute;
      left: 0;
      border-radius: 10px;
      background-color: #1296db;
    }
    .circle {
      width: 10px;
      height: 10px;
      position: absolute;
      top: -3px;
      left: 50px;
      border-radius: 50%;
      background: #000;
    }
  }
  .audio_bottom {
    z-index: 2;
    width: 100%;
    height: 57px;
    opacity: 1;
    border-radius: 22px 22px 0px 0px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.18);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px 50px;
    box-sizing: border-box;
    // background: #000;
  }
  // .progress_range {
  //   width: 100%;
  //   height: 5px;
  //   background-color: rgba(204, 225, 214, 1);
  //   &::-webkit-slider-thumb {
  //     -webkit-appearance: none;
  //     appearance: none;
  //     width: 15px;
  //     height: 15px;
  //     background-color: rgba(204, 225, 214, 1);
  //     border-radius: 50%;
  //     cursor: pointer;
  //   }
  // }

  .progress {
    width: 100%;
    background: rgba(204, 225, 214, 1);
    height: 6px;
    .progress_bg {
      height: 100%;
      .cur_progress {
        height: 100%;
        background: #426e52;
      }
    }
    .idot {
      width: 16px;
      height: 16px;
      position: relative;
      border-radius: 50%;
      background-color: black;
      top: -11px;
      vertical-align: middle;
    }
  }
  // .adm-slider-thumb {
  //   width: 10px !important;
  //   height: 10px !important;
  // }
  // .dot {
  //   width: 10px;
  //   height: 10px;
  //   border-radius: 50%;
  // }
}
