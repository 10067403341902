.login_page {
  .login_back {
    width: 443px;
    height: 321px;
    background: url("./../../assets/images/login_back.png");
    border-radius: 0 0 140% 50%;
    position: fixed;
    top: -96px;
    left: -20px;

    .clear {
      clear: both;
    }
  }
  .back_box {
    position: fixed;
    top: 23px;
    left: 25px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: #fff;
    display: flex;
    // justify-content: center;
    align-items: center;
  }
  .back_icon {
    margin-left: 4px;
    font-size: 18px;
    // color: @primary-color; // Make sure to define this variable or replace it with a hex code
  }
  .welcome_text {
    margin-top: 264px;
    text-align: center;
    font-family: Poppins;
    font-size: 36px;
    font-weight: 500;
    line-height: 50px;
    color: #17412b;
    text-shadow: 4px 4px 4px #00000040;
    // text-shadow: 0px 4px 4px 0px #00000040;
  }
  .login_tips {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    margin-bottom: 10px;
  }
  .login_form {
    --border-bottom: 0px;
    --border-top: 0px;
    .adm-list-item-content {
      border-top: 0px;
    }
    .login_account_form_item {
      position: relative;
      padding-left: 25px;
    }
    .login_email_icon {
      position: absolute;
      left: 39px;
      top: 36px;
    }
    .login_account {
      width: 325px;
      height: 62px;
      background-color: #ebf2f0;
      border-radius: 16px;
      --placeholder-color: #17482e7d;
      padding-left: 40px;
      box-sizing: border-box;
      --color: #426e52;
    }
    .get_code {
      width: 325px;
      height: 62px;
      box-sizing: border-box;
      margin-left: 25px;
      border-radius: 16px;
      background-color: #ebf2f0;
      .adm-list-item-content-main {
        padding: 17px 0px;
      }
      .get_code_input {
        --placeholder-color: #17482e7d;
        --color: #426e52;
      }
      .send_code_disabled {
        cursor: not-allowed;
      }
    }
    //
    .check_form_item {
      padding-left: 25px;
      width: 325px;
      .adm-form-item-child-inner {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .forgot_text {
          width: 108px;
          height: 17px;
          /** 文本1 */
          font-size: 12px;
          font-weight: 400;
          letter-spacing: 0px;
          line-height: 17px;
          color: rgba(23, 72, 46, 1);
          text-align: center;
          vertical-align: top;
        }
      }
      .login_check_box {
        --icon-size: 15px;
        --font-size: 12px;
      }
    }
    .adm-form-footer {
      padding-left: 25px;
    }
    .login_btn {
      width: 325px;
      height: 51px;
      opacity: 1;
      border-radius: 100px;
      background: rgba(66, 110, 82, 1);
    }
  }
  .signup-link {
    text-align: center;
    padding-bottom: 30px;
    .first_text {
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0.63px;
      line-height: 19.6px;
      color: rgba(139, 139, 139, 1);
    }
    .second_text {
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 0.63px;
      line-height: 19.6px;
      color: rgba(23, 72, 46, 1);
      text-align: center;
    }
  }
}
